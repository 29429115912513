<template>
  <template v-if="components && components.length > 0">
    <component
      v-for="(component, index) in components"
      :is="component.component"
      :key="index"
      :data="component.data"
    />
  </template>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Composables
import useGetData from '~/composables/api/useGetData';

// Types
import type { IPageData } from '~/typings/types/data.types';
import type { IComponentAsync } from '~/typings/types/component.types';

const { getPageAsyncComponents, getPage } = useGetData<any>();

let components = shallowRef<IComponentAsync[] | null>([]);

components.value = await getPageAsyncComponents();

const pageData = ref<IPageData>(getPage());

useHead({
  title: pageData.value.meta_title,
  meta: [
    { name: 'description', content: pageData.value.meta_description },
    {
      name: 'keywords',
      content: pageData.value.meta_keywords.length
        ? pageData.value.meta_keywords.join(', ')
        : undefined,
    },
  ],
});
</script>
